import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from './Layout';
import config from './temp/config';
import componentFactory from './componentFactory';
import { RouteDataManager } from './lib/RouteDataManager';
import { useAppStateContext } from './lib/AppStateProvider';
import { ComponentFactoryContextProvider } from './lib/ComponentFactoryProvider';
// This context is required for @dtcm components to fetch context properly.
import {
  SitecoreContextReactContext as DtcmUiComponentsSitecoreContext,
} from '@dtcm/ui-components';
import { get } from './lib/getPropertyByPath';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
// This is the main entry point of the app invoked by the renderer (server or client rendering).
const AppRoot = ({ Router }: { Router: any }) => {
  const { appState, actions } = useAppStateContext();
  const isEmptyLayout = get(appState, 'dictionary.viewBag.isEmptyLayout', false);

  // Accessibility settings require DOM manipulation, so initialize them after first mount/render.
  // `useEffect` will only fire once when providing an empty array as the second argument.
  useEffect(() => {
    actions.initializeAccessibilitySettings({
      contrast: appState.selectedContrastValue,
      fontSize: appState.selectedFontSize as any,
    });
  }, []);

  const sitecoreContextData: any = {
    route: null,
    rtl: false,
    config: config,
    pageEditing: false,
    origin: '',
    breadcrumb: [],
    isPersonalised: false,
    volume: 0
  };

  if (appState.routeData && appState.sitecoreContextData) {
    const identityPath = appState.sitecoreContextData.configuration.globalApi.apiGlobal
    const clientId = appState.sitecoreContextData.configuration.globalApi.clientId
    sitecoreContextData.route = appState.routeData;

    Object.assign(sitecoreContextData, appState.sitecoreContextData, {
      isPersonalised: appState.isPersonalised,
      darkMode: appState.darkMode,
      volume: appState.volume,
      videosList: appState.videosList,
      selectedFontSize: appState.selectedFontSize,
      actions: {
        changeThemeFunction: (value: boolean) => actions.changeThemeFunction(value, identityPath),
        fetchUserSettings: () => actions.fetchUserSettings(identityPath),
        fetchVideosList: () => actions.fetchVideosList(identityPath),
        getLikes: ( videoIds: any) => actions.getLikes( videoIds, identityPath),
        setLike: ( videoId: any  ) => actions.setLike( videoId, identityPath, clientId),
        setVideoTimestamp: (videoId: any, timestamp: number) => actions.setVideoTimestamp(videoId, timestamp, identityPath, clientId),
        setVideoVolumeFunction: ( value: number) => actions.setVideoVolumeFunction(value, identityPath, clientId),

      }
    });
  }
  const captchaKey = get(sitecoreContextData, 'configuration.globalApi.recaptchaKey');
  const languageCode: string = get(sitecoreContextData, 'language');

  return (
    <Router>
      {!isEmptyLayout && <ScrollToTop />}
      <RouteDataManager>
        <ComponentFactoryContextProvider componentFactory={componentFactory}>
          <DtcmUiComponentsSitecoreContext.Provider value={sitecoreContextData}>
            {
              captchaKey ? <GoogleReCaptchaProvider
                reCaptchaKey={captchaKey}
                language={languageCode}
                container={{
                  parameters: {
                    badge: 'inline',
                    theme: appState.darkMode ? 'dark' : 'light',
                  }
                }}
              >
                <Layout route={sitecoreContextData.route} />
              </GoogleReCaptchaProvider> : ''
            }
          </DtcmUiComponentsSitecoreContext.Provider>
        </ComponentFactoryContextProvider>
      </RouteDataManager>
    </Router>
  );
};

export default AppRoot;
